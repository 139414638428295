import { DeveloperDetailsFragment, EmployeeDetailsFragment, UserRole, UserType } from "@/graphql/__generated__/graphql-operations";
import { ArrowLeftStartOnRectangleIcon, Cog8ToothIcon, LanguageIcon, MoonIcon, PaintBrushIcon, SunIcon, UserGroupIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { useWeb3 } from "@/context/Web3Context";
import { logout } from "@/utils/authentication";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { propsProfile } from "./props";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuPortal, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger } from "@/components/ui/DropdownMenu";
import { typeDropdownLink, typeSublink } from "@/types";
import { AvatarComponent, FramerButton } from "@/components/shared";
import { Theme, useTheme } from "@/context/ThemeProvider";

/**
 * The `Profile` component renders a user profile dropdown menu with user details,
 * theme selection, language settings, and logout functionality. It also provides submenus
 * for selecting themes and languages, and handles signing out and wallet disconnection.
 *
 * @param {propsProfile} props - The props for the component.
 * @param {object} props.company - The company information associated with the user.
 * @param {object} props.user - The user information to display in the profile.
 * @param {string} props.user.firstname - The first name of the user.
 * @param {string} props.user.lastname - The last name of the user.
 * @param {UserRole} props.user.role - The role of the user.
 * @param {UserType} props.user.usertype - The type of the user.
 *
 * @returns {JSX.Element} The rendered Profile dropdown menu component.
 *
 * @example
 * // Sample usage in a parent component:
 * import { Profile } from './Profile';
 *
 * const company = { name: 'Tech Co.', logo: '/images/company-logo.png' };
 * const user = {
 *   firstname: 'John',
 *   lastname: 'Doe',
 *   role: UserRole.Superuser,
 *   usertype: UserType.Developer
 * };
 *
 * function App() {
 *   return (
 *     <div>
 *       <Profile company={company} user={user} />
 *     </div>
 *   );
 * }
 */
const Profile = ({ company, user }: propsProfile): JSX.Element => {

  const userName = `${user.firstname} ${user.lastname}`;

  const { disconnectWallet } = useWeb3();
  const { darkMode, setTheme, theme } = useTheme();

  const { t, i18n } = useTranslation(["components"]);
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const darkModeClass = darkMode ? "text-white" : "text-gray-600";
  const backgroundColour = darkMode ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";
  const hoverColour = darkMode ? "hover:bg-white/20": "hover:bg-gray-100";

  const languages: typeSublink[] = [
    { name: t("header.languages.en"), code: "en", icon: "🇬🇧"},
    { name: t("header.languages.de"), code: "de", icon: "🇩🇪"},
  ];

  const themes: typeSublink[] = [
    { name: "Dark", icon: <MoonIcon className={`mf-dropdown-link w-5 h-5 -mr-[2px] ${darkModeClass}`} />},
    { name: "Light", icon: <SunIcon className={`mf-dropdown-link w-5 h-5 -mr-[2px] ${darkModeClass}`} />},
    { name: "System", icon: <WrenchScrewdriverIcon className={`mf-dropdown-link w-5 h-5 -mr-[2px] ${darkModeClass}`} />},
  ];

  const handleSignoutClick = () => {
    logout();
    navigate("/login");
    disconnectWallet();
  };

  const dropdownLinksAlt: typeDropdownLink[] = [
    {name: t("header.dropdownlinks.language"), link: "", type: "Both", icon: <LanguageIcon className={`${darkModeClass} mf-dropdown-link`} />, sublinks: languages},
    {name: t("header.dropdownlinks.darkmode"), link: "", type: "Both", icon: <PaintBrushIcon className={`${darkModeClass} mf-dropdown-link`} />, sublinks: themes},
    {name: t("header.navlinks.manageusers"), link: user.__typename === "Developer"? "/admin/manageusers": "/user/manageusers", visibility: [UserType.Developer, UserRole.Superuser, UserRole.Admin], type: "Both", icon: <UserGroupIcon className={`${darkModeClass} mf-dropdown-link`} />},
    {name: t("header.dropdownlinks.settings"), link: "/user/settings", type: "Both", icon: <Cog8ToothIcon className={`${darkModeClass} mf-dropdown-link`} />},
    {name: t("header.dropdownlinks.signout"), link: "/login", type: "Both", icon: <ArrowLeftStartOnRectangleIcon className={`${darkModeClass} mf-dropdown-link`} />, onClick: handleSignoutClick},
  ];

  /**
   * Handles the active link styling based on the provided link data and sublink.
   *
   * @param {typeDropdownLink} linkData - The data for the dropdown link, containing its name.
   * @param {typeSublink} sublink - The sublink object, which includes its code and name.
   * @returns {string} A string representing the CSS classes for active styling, or an empty string if not active.
   *
   * @remarks
   * - If the link corresponds to the language option, it checks if the sublink's code matches the current language.
   * - If the link corresponds to the dark mode option, it checks if the sublink's name matches the current theme.
   */
  const handleActiveLink = (linkData: typeDropdownLink, sublink: typeSublink): string => {
    if(linkData.name === t("header.dropdownlinks.language")) {
      return sublink.code === currentLanguage ? !darkMode ? "bg-gray-300" : "bg-gray-500" : "";
    }
    if(linkData.name === t("header.dropdownlinks.darkmode")) {
      return sublink.name.toLowerCase() === theme ? !darkMode ? "bg-gray-300" : "bg-gray-500" : "";
    }
    return "";
  };

  /**
   * Handles the click event for submenu items in the profile dropdown.
   *
   * @param {typeDropdownLink} linkData - The data associated with the clicked link, containing its name.
   * @param {typeSublink} sublink - The data associated with the clicked sublink, containing its name.
   *
   * This function performs the following actions:
   * - Changes the application language if the clicked link corresponds to the language option.
   * - Updates the theme to dark or light mode based on the clicked sublink and user preferences.
   * - Dispatches a 'languagechange' event when the language is changed.
   * - Stores the user's dark mode preference in local storage.
   */
  const handleSubmenuOnClick = (linkData: typeDropdownLink, sublink: typeSublink) => {
    if (linkData.name === t("header.dropdownlinks.language")) {
      const selectedLang = languages?.find(lang => lang.name === sublink.name);
      if (selectedLang) {
        i18n.changeLanguage(selectedLang.code); // Change language
        window.dispatchEvent(new Event("languagechange"));
      }
    } else if (linkData.name === t("header.dropdownlinks.darkmode")) {
      setTheme(sublink.name.toLowerCase() as Theme);
    }
  };

  /**
   * UserInfo component displays user information including the user's name and company.
   *
   * @param {Object} props - The component props.
   * @param {string} props.userName - The name of the user. If not provided, the component returns null.
   * @param {string} [props.companyName] - The name of the company. If not provided, defaults to a developer label.
   * @param {boolean} [props.dropdownOnly] - Determines the visibility of the component based on screen size.
   *
   * @returns {JSX.Element | null} The rendered component or null if userName is not provided.
   */
  const UserInfo = ({userName, companyName, dropdownOnly}: {userName: string, companyName?: string, dropdownOnly?: boolean}): JSX.Element | null => {
    if(!userName) return null;
    return (
      <div className={`${dropdownOnly ? "block xl:hidden": "hidden xl:block ml-2"}`}>
        <p className={`mf-profile-name ${darkModeClass}`}>
          {`${userName}`}
        </p>
        {
          companyName ?
            <p className={`mf-profile-company ${darkModeClass}`}>
              {companyName}
            </p>
            :
            <p className={`mf-profile-company ${darkModeClass}`}>
              {t("header.developer")}
            </p>
        }
      </div>
    );
  };

  /**
   * Determines whether a link should be rendered based on the user's role and type.
   */
  const shouldRenderLink = (linkData: typeDropdownLink, user: EmployeeDetailsFragment | DeveloperDetailsFragment) => {
    const hasValidVisibility = !linkData.visibility || linkData.visibility.includes(user.role) || linkData.visibility.includes(user.__typename);
    const hasValidType = linkData.type.includes(user.role) || linkData.type.includes(user.usertype) || linkData.type === "Both";
    return hasValidVisibility && hasValidType;
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={`focus:outline-none ${hoverColour}`}>
        <FramerButton
          whileHover={{ borderColor: "#EE5300" }}
          className="mf-profile-dropdown"
          whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
        >
          <AvatarComponent size="28" userName={userName} logo={company?.logo} />
          <UserInfo userName={userName} companyName={company?.name} />
        </FramerButton>
      </DropdownMenuTrigger>

      {/* Menu content */}
      <DropdownMenuContent
        align="end"
        avoidCollisions
        className={`mf-dropdown-menu min-w-[200px] pb-2 ${backgroundColour}`}
      >
        <div className="flex xl:hidden gap-2 items-center mb-2 px-2 pt-2">
          <AvatarComponent size="40" userName={userName} logo={company?.logo} />
          <UserInfo userName={userName} companyName={company?.name} dropdownOnly />
        </div>

        {/* Menu items */}
        <DropdownMenuGroup className="px-2">
          {
            dropdownLinksAlt.map((linkData) => (
              shouldRenderLink(linkData, user) && (
                linkData.sublinks ? (
                  // Main dropdown item (trigger for submenu)
                  <DropdownMenuSub key={`dropdown-sub-${linkData.name}`}>
                    <DropdownMenuSubTrigger
                      className={`mf-dropdown-link-container ${hoverColour}`}
                    >
                      {linkData.icon}
                      {linkData.name}
                      {
                        linkData.name === t("header.dropdownlinks.language") &&
                        <div className="ml-1">{languages?.find((lang) => lang.code === currentLanguage)?.icon}</div>
                      }
                    </DropdownMenuSubTrigger>

                    {/* Submenu content */}
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent
                        className={`px-2 py-1 rounded-md focus:outline-none min-w-[120px] shadow-md border-0 ${backgroundColour}
                                  max-md:data-[state=open]:translate-x-[50px] max-[329px]:data-[state=open]:-translate-x-[50px]`}
                      >
                        {linkData.sublinks?.map((sublink: typeSublink) => (
                          <DropdownMenuItem
                            key={`sublink-${sublink.name}`}
                            className={`cursor-pointer mf-dropdown-link-container ${hoverColour}
                                        ${handleActiveLink(linkData, sublink)}`}
                            onClick={() => handleSubmenuOnClick(linkData, sublink)}
                          >
                            {sublink.icon}
                            <p className="ml-2">
                              {sublink.name}
                            </p>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                ) : ( // ***
                  <DropdownMenuItem
                    key={`dropdown-${linkData.name}`}
                    className={`focus:outline-none rounded-md flex items-center px-2 py-1 cursor-pointer ${hoverColour}`}
                    onClick={linkData?.onClick ?? (() => navigate(linkData.link))}
                  >
                    <div className="">{linkData.icon}</div>
                    <div className="">{linkData.name}</div>
                  </DropdownMenuItem>
                ))
            ))
          }
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Profile;
